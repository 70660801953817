import { StrictMode } from 'react';
import { ChakraProvider,  extendTheme } from "@chakra-ui/react";
import { BrowserRouter } from 'react-router-dom';
import * as ReactDOM from 'react-dom';

import App from './app/app';
import GlobalStyles from './app/components/GlobalStyles';

const colors = {
  gray: {
    900: '#000',
    800: '#333',
    700: '#666',
    600: '#ccc',
  },
  orange: {
    200: '#ED8022'
  },
  green: {
    200: '#009898'
  }
}

const fonts = {
  body: 'arial, sans-serif',
  heading: 'rigid-square, sans-serif',
  subhead: 'acumin-pro, sans-serif'
}

const theme = extendTheme({colors, fonts})

ReactDOM.render(
  <StrictMode>
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <GlobalStyles />
        <App />
      </BrowserRouter>
    </ChakraProvider>
  </StrictMode>,
  document.getElementById('root')
);

