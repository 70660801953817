import styled from '@emotion/styled';
import { Box, Heading, Grid, GridItem, Image, Text, Flex } from "@chakra-ui/react";
import Top from '../components/top';
import Form from '../components/ContactForm';

const H1 = styled(Heading)`
    font-weight: 400;
`;

const benefits = [
    {
        t: "A GREEN PRODUCT",
        s: "All Natural, Non-Chemical",
        p: "Tetrapore is a safe for the environment,  alternative to other chemical products on the market. Reduces exposure to other chemical admixtures typically used in concrete batch mixes.",
        img: "./assets/1.png"
    },
    {
        t: "INCREASE P.S.I.",
        s: "15-22%",
        p: "Tetrapore concrete additive will increase P.S.I. and flexural strength of concrete which can provide a stronger, more durable concrete at a reduced cost to other chemical alternatives. ",
        img: "./assets/2.png"
    },
    {
        t: "SLUMP + WORKABILITY",
        s: "Easy to Trowel / Less Water",
        p: "Tetrapore will allow for a much more workable and trowelable concrete even with a reduction of water and slump due to how the nano clays interact with the cement making it almost like toothpaste.",
        img: "./assets/3.png"
    },
    {
        t: "WATER PROOFING",
        s: "Limits Water Penetration",
        p: "Tetrapore concrete additive binds the cement together at the molecular level which will produce a denser less porus concrete which helps prevent water penetration and increases life of the concrete.",
        img: "./assets/4.png"
    },
    {
        t: "PUMPING AGENT",
        s: "No Packing Hose",
        p: "Tetrapore admixture is the best pumping agent on the market. It will increase the flow of your concrete even with reduced water making a more workable, and thicker cement for pump and spray applications.",
        img: "./assets/5.png"
    },
    {
        t: "EASY FORM RELEASE",
        s: "Labor Cost Savings",
        p: "Tetrapore makes your concrete less sticky as it wants to bind to itself making cleanup of hoses, forms, and molds a much easier process without the need for a chemical.",
        img: "./assets/6.png"
    }
]


const Home = () => {

    return(
        <>
            <Box bg="gray.700" padding="0 1rem">
                <Top />
            </Box>
            <Box padding="0 1rem">
                <Box margin="4rem auto" maxWidth="1410px">
                    <H1 as="h1" size="lg" paddingTop="2rem" color="gray.800">Tetrapore Benefits</H1>
                    <Grid templateColumns='repeat(4, 1fr)' gap={8} marginTop="2rem" >
                        {benefits.map((ben, i) => 
                            <GridItem w='100%' h='max-content' bg='white' justifyContent="center" textAlign="center">
                                <Flex width="100%" justifyContent="center"><Image src={ben.img} align="center" boxSize='100px' /></Flex>
                                <Heading as="h2" size="md" color={i === 0 ? "green.200" : "orange.200"} margin="1rem 0 .25rem 0">{ben.t}</Heading>
                                <Heading as="h3" size="sm" color="gray.800">{ben.s}</Heading>
                                <Text as="p" color="gray.700">{ben.p}</Text>
                            </GridItem>
                        )}
                        <GridItem colSpan={2} w='100%' h='max-content' bg='green.200'>
                            <Flex p="3rem">
                                <Box><Image src="./assets/leaf.png" alt="Tetrapore, a green product" /></Box>
                                <Box marginLeft="2rem">
                                    <Heading size="lg" color="white" as="h2">
                                        A GREEN<br />
                                        NON-CHEMICAL <br />
                                        ALTERNATIVE
                                    </Heading>
                                    <Text as="p" fontFamily="acumin-pro" fontSize="20px" fontWeight="700">
                                    Multi-Benefit Admixture with zero harsh chemicals that is both safe for the environment and the concrete professional.
                                    </Text>
                                </Box>
                            </Flex>
                        </GridItem>
                    </Grid>
                </Box>
            </Box>
            <Box bg="orange.200"  p="4rem 1rem">
                <Box margin="0 auto" maxWidth="1410px" textAlign="center">
                    <Heading as="h4" fontSize="40px" color="white" marginBottom=".5rem">“ RELIABLE AND READY TO USE ”</Heading>
                    <Heading as="h5" fontSize="20px" color="gray.900" sx={{fontFamily:"acumin-pro, sans-serif"}}>
                        Recommended by SCIP industry veteran Rodney Hadrian,<br /> 
                        President of Tridipanel 3D concrete panels, who has been building with concrete for the past three decades.
                    </Heading>
                </Box>
            </Box>
            <Box bg="gray.900" p="2rem 1rem">
                <Box margin="0 auto" maxWidth="1410px">
                    <H1 as="h1" size="lg" paddingTop="1rem" color="white" marginBottom="3rem">Tetrapore Technology</H1>
                    <Flex>
                        <Text as="p" color="gray.400" paddingRight="4rem" lineHeight="8">
                            Tetrapore has developed an Eco-friendly, non-toxic liquid additive for concrete based on its recently developed Nano-ceramic Polymer Technology (NPT). Due to its nano-scale (&#62;2-50nm), 
                            NPT interacts at the atomic level with the cement and aggregates to improve the physical and chemical properties of the concrete. This nano-technology represents the next generation of 
                            “Green” high functioning additives for Portland cement-based products.
                            <br /><br />
                            Nano-ceramic Polymer Technology (NPT) describes a liquid nanoceramic catalytic polymer system that improves how different molecules within composites interact with each other at the molecular level; 
                            this nano-ceramic system also imparts the characteristics of the flexible mesoporous inorganic polymer (MIP) and other NPT ceramic forms that cross-link and stabilize the ceramic system. 
                            The NPT-generated catalytic changes reduce the amount of energy required for curing and accelerate the particle stacking and composite maturation. Thus, the concrete composite cures in less time and achieves a higher density.
                        </Text>
                        <Image src="../assets/tech.png" marginTop="-5rem" />
                    </Flex>
                </Box>
            </Box>
            <Box p="2rem 0" id="form">
                <Box margin="0 auto" maxWidth="1410px">
                    <H1 as="h1" size="lg" paddingTop="1rem" color="gray.800" marginBottom="3rem">Contact Tetrapore</H1>
                    <Form />
                </Box>
            </Box>
        </>
    )
}

export default Home;