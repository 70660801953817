import { Link, animateScroll as scroll } from "react-scroll";
import styled from '@emotion/styled';
import { Box, Flex, Button, Image } from "@chakra-ui/react";

const Btn = styled.a`
    border: 4px solid #fff;
    padding: .25rem .5rem;
    font-family: "acumin-pro";
    font-size: 16px;
    color: #fff;
`;

const Header = () => {

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    return(
        <Flex justifyContent="space-between" position="relative">
            <Box>
                <Image src="./assets/logo.png" position="absolute" zIndex="999" top="-1rem" left="1rem" />
            </Box>
            <Box>
                <Btn href="#form">CONTACT US</Btn>
            </Box>
        </Flex>
    )
}

export default Header;