import styled from '@emotion/styled';
import { Box, Flex,Text, Image, Heading } from "@chakra-ui/react";

const Top = () => {

    return(
        
            <Box maxWidth="1410" margin="0 auto 100px auto" position="relative" height="500px" >
                
                <Image src="./assets/top.jpg" position="absolute" />
                <Box position="absolute" top="200px" left="150px">
                    <Box w="138px" h="5px" bg="orange.200" marginBottom="1rem" />
                    <Heading as="h1" fontSize="40px" color="white">
                        A Safe, Multi-Benefit, <br />
                        Non-Chemical Concrete <br />
                        Admixture.
                    </Heading>
                    <Text fontFamily="heading" color="orange.200" fontWeight="400" fontSize="28px">
                        Tetrapore is a Nano Ceramic Polymer...<br />
                        An All-Natural first of its Kind Liquid Pozzolan.
                    </Text>
                </Box>
            </Box>
    )
}

export default Top;