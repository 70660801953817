import styled from '@emotion/styled';

//import { ReactComponent as Logo } from './logo.svg';
//import star from './star.svg';

import { Route, Link } from 'react-router-dom';
import Header from './components/header';
import { Box, Flex,Text, Image } from "@chakra-ui/react";
import Home from './views/home';


export function App() {
  return (
    <Box>
      <Box bg="gray.700" padding="0 1rem">
        <Box maxWidth="1410" margin="0 auto" p="1rem 0">
          <Header />
        </Box>
      </Box>
        <Route path="/" exact render={Home} />
        <Route
          path="/page-2"
          exact
          render={() => (
            <div>
              <Link to="/">Click here to go back to root page.</Link>
            </div>
          )}
        />
        {/* END: routes */}
        
      </Box>
  );
}

export default App;
